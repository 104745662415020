
<template>
    <div class="message_detail">
        <div class="top" style="height:40px">
            <van-sticky :offset-top="0">
                <div class="public_header">
                    <!-- <router-link to> -->
                        <a href="javascript:;">
                            <van-icon @click="$router.back(-1)" name="arrow-left" color="#fff" />
                        </a>
                    <!-- </router-link> -->
                    <span>站内信</span>
                    <span>
                        <van-icon @click="delBtn()" name="delete-o" color="#fff" />
                    </span>
                </div>
            </van-sticky>
        </div>
        <div class="message_detail_main">
            <div class="message_detail_title">{{this.content.title}}</div>
            <div class="message_detail_time">{{this.content.createTime}}</div>
            <!-- <div class="public_impression"></div> -->
            <div class="message_detail_article">
                <p>{{this.content.content}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MessageDetail',
    data() {
        return {
            id:'',
            content:{}
        }
    },
    created(){
        let data = {id:this.$route.query.id};
        this.id = data;
        this.$api.message.getDetail(data).then((res)=>{
            this.content = res.data;
        })
    },
    methods:{
        delBtn(){
            this.$api.message.delete(this.id).then((res)=>{
                if(res.code==200){
                    this.$toast({
                        message: '删除成功',
                        duration: 1500
                    });
                    // this.$router.push({
                    //     path: '/message'
                    // })
                    this.$router.back(-1)
                }
            })
        }
    }
}
</script>
<style scoped>
.message_btn>div{
   padding: .9375rem 1.4375rem; 
}
.message_btn .reg_btn{
    color: #FF4E00 !important;
    border-color: #FF4E00;
}
.message_btn  .aline button{
    width: 30%;
}
.message_btn .aline{
    display: flex;
    justify-content: space-between;
}
.blue{
    color: #007eff;
}
.gray{
    color: #9b9b9b;
}
.orange{
    color: #ff4e00;
}
.mesage_list{
    padding: .9375rem 1.4375rem;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    align-items: center;
}
.message_price{
    padding: .9375rem 1.4375rem;
    display: flex;
    justify-content: space-between;
    color: #4c4c4c;
}
.message_price_l{
    text-align: left;
    font: .875rem/1.5em Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
}
.message_tips{
     padding: .9375rem 1.4375rem;
     text-align: left;
}
/* .index_list{
    padding: .9375rem 1.4375rem;
} */
.mesage_list .index_list_l{
    align-items: center;
}
.public_impression{
    background: #ececec;
    height: .9375rem;
}
.public_header>span:last-child {
    width: 26px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 3%;
}
.public_header>span:last-child i{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    font-size: 26px;
}
.message_detail{
    min-height: 100%;
    background: #fff;
}
.message_detail_title{
    font: 1.0625rem/1.5rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #313131;
    padding-top: 1.40625rem;
    background: #fff;
}
.message_detail_time{
    font: .875rem/1.25rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #909090;
    padding-bottom: .59375rem;
    background: #fff;
}
.message_detail_article{
    text-align: left;
    padding: .9375rem 1.4375rem;
}
.message_detail_article p{
    text-indent: 2em;
    font: .875rem/1.53125rem Open Sans,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,Segoe UI,Arial,Roboto,PingFang SC,miui,Hiragino Sans GB,Microsoft Yahei,sans-serif;
    color: #4c4c4c;
}
</style>